import React from 'react'
import { Icon } from '../Icon/icon'

export interface TileProps {
    iconName: string,
    description: string,
    url: string
}

export function Tile(props: TileProps) {
    const { description, iconName, url } = props

    return (
        <div className="tile">
            <a className="tile-inner" href={url}>
                <Icon className="tile-icon" name={iconName} viewBox="0 0 60 60" />
                <h4 className="tile-description">{description}</h4>
            </a>
        </div>
    )
}
