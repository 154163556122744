import classnames from 'classnames'
import React, { Component } from 'react'
import { updateBody } from '../../../utility/update-body'
import { Icon } from '../Icon/icon'
import './header.scss'

interface HeaderState {
    isNavigationActive: boolean,
    navigationItemOpen: number,
    searchOpen: boolean,
}

export interface HeaderProps {
    data: Array<MenuItem>
}

export interface MenuItems {
    menuItem: Array<MenuItem>
}

export class Header extends Component<HeaderProps, HeaderState> {
    protected searchRef: React.RefObject<HTMLInputElement>

    constructor(props: HeaderProps) {
        super(props)
        this.state = this.defaultState()

        this.searchRef = React.createRef()
        this.handleSearchSubmit = this.handleSearchSubmit.bind(this)
        this.handleToggleChildNavigation = this.handleToggleChildNavigation.bind(this)
        this.handleToggleMobileNavigation = this.handleToggleMobileNavigation.bind(this)
        this.handleNavLinkClick = this.handleNavLinkClick.bind(this)
        this.conponentDidMount = this.conponentDidMount.bind(this)
    }

    defaultState(): HeaderState {
        return {
            isNavigationActive: false,
            navigationItemOpen: null,
            searchOpen: false,
        }
    }

    handleToggleChildNavigation(index: number) {
        let { navigationItemOpen } = this.state

        if (navigationItemOpen === index) {
            navigationItemOpen = this.defaultState().navigationItemOpen
        } else {
            navigationItemOpen = index
        }

        this.setState({ navigationItemOpen })
    }

    conponentDidMount() {
        const cx = '56ae2eed06b91f2e5'
        const gcse = document.createElement('script')
        gcse.type = 'text/javascript'
        gcse.async = true
        gcse.src = 'https://cse.google.com/cse.js?cx=' + cx
        const s = document.getElementsByTagName('script')[0]
        s.parentNode.insertBefore(gcse, s)
    }

    handleToggleSearch() {
        const searchOpen = this.state.searchOpen

        // When opening the bar, focus
        if (!searchOpen) {
            setTimeout(() => (this.searchRef.current.focus()), 50)
        }

        // When clicking a second time, and there is text, assume search
        if (searchOpen && this.searchRef.current.value.length > 0) {
            this.handleSearchSubmit()
        } else {
            this.setState(state => ({
                ...state,
                searchOpen: !searchOpen,
            }))
        }
    }

    handleToggleMobileNavigation() {
        this.setState({ isNavigationActive: !this.state.isNavigationActive },
          () => updateBody(this.state.isNavigationActive)
        )
    }

    handleNavLinkClick() {
        const { isNavigationActive } = this.state

        // Checks whether mobile navigation is active, then closes it and update body to remove fixed positioning
        if (isNavigationActive) {
            this.handleToggleMobileNavigation()
        }
    }

    handleSearchSubmit(event: React.FormEvent = null) {
        if (event) {
            event.preventDefault()
        }
        const search = encodeURIComponent(this.searchRef.current.value)
        const port = location.port && ![ '80', '443' ].includes(location.port) ? `:${ location.port }` : ''
        window.location.href = `${ location.protocol }//${ location.hostname }${ port }/search?q=${ search }`
    }

    renderNavigationItems() {
        return this.props.data.map(({ link, label, current, isNewWindow, children }, itemIndex) => {
            const childrenClass = classnames('navigation-children', {
                'is-active': itemIndex === this.state.navigationItemOpen
            })

            const toggleClass = classnames('navigation-item-toggle', {
                'is-active': itemIndex === this.state.navigationItemOpen
            })

            const linkClass = classnames('link', {
                'current': current
            })

            return (
              <div
                className="navigation-item"
                key={ itemIndex }
              >
                  <a
                    target={`${isNewWindow ? '_blank' : '_self'}`}
                    className={linkClass}
                    href={ link }
                    rel={`${link.includes(location.hostname) || link.startsWith('/') ? '' : 'noopener noreferrer'}`}
                    onClick={ () => this.handleNavLinkClick() }
                  >
                      { label }
                  </a>
                  { children &&
                    <button className={ toggleClass } onClick={ () => this.handleToggleChildNavigation(itemIndex) }>
                        <Icon name="arrow-down"/>
                    </button>
                  }
                  { children &&
                    <nav className={ childrenClass }>
                        { children.map(({ link: navLink, label: navLabel, current: navCurrent, isNewWindow: navIsNewWindow, children }, childIndex) => {
                            const subLinkClass = classnames('sub-link', {
                                'current': navCurrent
                            })

                            return (
                              <div className="navigation-item-sub" key={`top-nav-${childIndex}`}>
                                  <a
                                    target={`${navIsNewWindow ? '_blank' : '_self'}`}
                                    className={subLinkClass}
                                    href={ navLink }
                                    rel={`${navLink.includes(location.hostname) || navLink.startsWith('/') ? '' : 'noopener noreferrer'}`}
                                    key={ childIndex }
                                    onClick={ () => this.handleNavLinkClick() }
                                  >
                                      { navLabel }
                                  </a>
                                  { children &&
                                    <nav className={ `sub-navigation-item` }>
                                        { children.map(({ link: navLink, label: navLabel, current: navCurrent, isNewWindow: navIsNewWindow}, childIndex) => {
                                            const subSubLinkClass = classnames('sub-link', {
                                                'current': navCurrent
                                            })

                                            return (
                                              <a
                                                target={`${navIsNewWindow ? '_blank' : '_self'}`}
                                                className={subSubLinkClass}
                                                href={ navLink }
                                                rel={`${navLink.includes(location.hostname) || navLink.startsWith('/') ? '' : 'noopener noreferrer'}`}
                                                key={ childIndex }
                                                onClick={ () => this.handleNavLinkClick() }
                                              >
                                                  { navLabel }
                                              </a>
                                            )
                                        }) }
                                    </nav>
                                  }
                              </div>
                            )
                        }) }
                    </nav>
                  }
              </div>
            )
        })
    }

    render() {
        const { isNavigationActive, searchOpen } = this.state

        const navigationClass = classnames('navigation', {
            'is-active-mobile': isNavigationActive, // on moblie, visible when toggled
            'is-active-desktop': !searchOpen // on desktop, visible except when searching
        })

        const searchBarClass = classnames('search-bar', {
            'is-active-mobile': isNavigationActive, // on desktop, visible when menu is open
            'is-active-desktop': searchOpen, // on desktop, visible when searching
        })

        const searchClass = classnames('search-button', {
            'is-active': searchOpen
        })

        const menuToggleClass = classnames('header-menu-toggle', {
            'is-hidden': isNavigationActive
        })

        const logoClass = classnames('header-logo', {
            'is-hidden': isNavigationActive
        })

        const donateButtonClass = classnames('donate-button', {
            'is-hidden': isNavigationActive
        })

        const closeToggleClass = classnames('header-close-toggle', {
            'is-hidden': !isNavigationActive
        })

        return (
          <header className="header">
              <div className="header-inner || constrain-width large no-pad">
                  <button className={ menuToggleClass } onClick={ () => this.handleToggleMobileNavigation() } aria-label="Open menu">
                      <Icon name="hamburger"/>
                  </button>
                  <div className={ logoClass }>
                      <a href="/" aria-label="Homepage">
                          <Icon name="logo" viewBox="0 0 110 70"/>
                      </a>
                  </div>
                  <button className={ closeToggleClass } onClick={ () => this.handleToggleMobileNavigation() } aria-label="Close menu">
                      <Icon name="close"/>
                  </button>
                  <nav className={ navigationClass }>
                      { this.renderNavigationItems() }
                  </nav>
                  <div className={ searchBarClass }>
                      <form onSubmit={ this.handleSearchSubmit }>
                          <input ref={ this.searchRef } className="text" type="text" placeholder="Search Allergy NZ"/>
                      </form>
                  </div>
                  <button className={ searchClass } onClick={ () => this.handleToggleSearch() }>
                      <Icon name="search" width="20" height="20" viewBox="0 0 18 18"/>
                  </button>
                  <a href="/donate" className={ donateButtonClass }>Donate</a>
              </div>
          </header>
        )
    }
}

export interface MenuItem {
    link: string,
    current: boolean,
    isNewWindow: boolean,
    label: string,
    children?: Array<MenuItem>,
}
