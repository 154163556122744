import classnames from 'classnames'
import React from 'react'
import { Section } from '../../partials/section'

interface TextContentProps {
    title: string
    description: string
    showTitle: boolean
    columns?: number
}

export function TextContent({ title, showTitle, description, columns = 1 }: TextContentProps) {
    const descriptionClass = classnames('text-content-description', {
        'columns': columns
    })

    return (
        <Section title={showTitle ? title : null} type="constrain" className="text-content">
            <div className={descriptionClass} dangerouslySetInnerHTML={{ __html: description }} style={{columns: columns || 1}}/>
        </Section>
    )
}
