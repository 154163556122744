import React from 'react'

export interface HeroProps {
    title: string
    imageUrl: string
    showTitle: boolean
}

export function Hero(props: HeroProps) {
    const { title, imageUrl , showTitle} = props

    return (
        <header role="heading" className="hero">
            <div className="hero-wrapper" style={{backgroundImage: `url(${imageUrl})`}}>
                <div className="hero-inner">
                    { showTitle ?
                        <div className="hero-header || constrain-width">
                            <h1 className="hero-title">{title}</h1>
                        </div>
                        : null
                    }
                </div>
            </div>
        </header>
    )
}
