import classnames from 'classnames'
import React, { Component } from 'react'
import Slider from 'react-slick'
import { IMAGE_GALLERY_CAROUSEL, VIDEO_GALLERY_CAROUSEL } from '../../../config/carousel'
import { isMobileView } from '../../../config/screen-width'
import { Icon } from '../../partials/Icon/icon'
import { Section } from '../../partials/section'
import ReactPlayer from 'react-player'
import { VideoThumbnail } from '../../partials/VideoThumbnail/videoThumbnail'

const MIN_SLIDES_NUMBER = 3

export interface VideoGalleryItemProps {
    title: string
    url: string
}

export interface VideoGalleryProps {
    title: string
    showTitle: boolean
    items: Array<VideoGalleryItemProps>
}

export interface VideoGalleryState {
    currentIndex: number
    mobileView: boolean
}

export class VideoGallery extends Component<VideoGalleryProps, VideoGalleryState> {
    carousel: any

    constructor(props: VideoGalleryProps) {
        super(props)

        this.carousel = React.createRef()
        this.handleOnPreviousClick = this.handleOnPreviousClick.bind(this)
        this.handleOnNextClick = this.handleOnNextClick.bind(this)

        this.state = {
            currentIndex: 0,
            mobileView: true
        }
    }

    // Lifecycle
    componentDidMount() {
        this.setState({ mobileView: isMobileView() })
    }

    componentDidUpdate(nextProps: VideoGalleryProps) { // eslint-disable-line react/no-deprecated
        if (this.carousel && nextProps.items !== this.props.items && nextProps.title !== this.props.title) {
            this.carousel.current.slickGoTo(0)
        }
    }

    // componentWillReceiveProps(nextProps: VideoGalleryProps) { // eslint-disable-line react/no-deprecated
    //     if (this.carousel && nextProps.items !== this.props.items && nextProps.title !== this.props.title) {
    //         this.carousel.current.slickGoTo(0)
    //     }
    // }

    // Handler
    handleOnNextClick() {
        this.carousel.current.slickGoTo(this.state.currentIndex + 1)
    }

    handleOnPreviousClick() {
        this.carousel.current.slickPrev(this.state.currentIndex - 1)
    }

    // Render
    renderNumberOfSlides() {
        const { items } = this.props
        const { currentIndex } = this.state

        return (
            <span className="carousel-range" aria-hidden={true}>{`${currentIndex + 1} of ${items.length}`}</span>
        )
    }

    renderDesktopCarousel() {
        const { items } = this.props
        const { currentIndex } = this.state

        return (
            <div className="video-gallery-inner">
                {items.map((item, index) => {
                    const imageClass = classnames('video-gallery-content', {
                        'is-hidden': index !== currentIndex
                    })

                    return (
                        <div className={imageClass} key={index}>
                            <div className="video-gallery-video || preserve-aspect-ratio">
                                <div className="preserve-aspect-ratio-media">
                                    <ReactPlayer url={item.url} playing={false} width="100%" height="100%"/>
                                </div>
                            </div>
                            <p className="video-gallery-description">{item.title}</p>
                        </div>
                    )
                })}
                <div className="carousel">
                    {this.renderNumberOfSlides()}
                    {items.length >= MIN_SLIDES_NUMBER &&
                        <div>
                            <button className="carousel-button previous" onClick={this.handleOnPreviousClick}>
                                <Icon name="arrow-left" />
                            </button>
                            <button className="carousel-button next" onClick={this.handleOnNextClick}>
                                <Icon name="arrow-right" />
                            </button>
                        </div>
                    }
                    <Slider
                        className="carousel-slider"
                        ref={this.carousel}
                        {...VIDEO_GALLERY_CAROUSEL}
                        beforeChange={(previous, current) => {
                            this.setState({ currentIndex: current })
                        }}
                    >
                        {items.map((item, index) => {
                            return (
                                <div className="video-gallery-item" key={index}>
                                    <div className="video-gallery-item-media || preserve-aspect-ratio overlay-image">
                                        <div className="preserve-aspect-ratio-media">
                                            <VideoThumbnail url={item.url}/>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </Slider>
                </div>
            </div>
        )
    }

    renderMobileCarousel() {
        const { items } = this.props

        return (
            <div className="video-gallery-inner">
                <div className="carousel">
                    <Slider
                        className="carousel-slider"
                        ref={this.carousel}
                        {...VIDEO_GALLERY_CAROUSEL}
                        beforeChange={(previous, current) => {
                            this.setState({ currentIndex: current })
                        }}
                    >
                        {items.map((item, index) => {
                            return (
                                <div className="video-gallery-item" key={index}>
                                    <div className="video-gallery-item-media || preserve-aspect-ratio overlay-image">
                                        <div className="preserve-aspect-ratio-media">
                                            <ReactPlayer url={item.url} playing={false} width="100%" height="100%"/>
                                        </div>
                                    </div>
                                    <div className="video-gallery-item-content">
                                        <p className="description">{item.title}</p>
                                    </div>
                                </div>
                            )
                        })}
                    </Slider>
                </div>
            </div>
        )
    }

    render() {
        const { title, items } = this.props
        const { mobileView } = this.state

        if (items.length < MIN_SLIDES_NUMBER) {
            return (
                <div>{`Minimum amount of slides for Video Gallery is ${MIN_SLIDES_NUMBER}`}</div>
            )
        }

        return (
            <Section title={title} className="video-gallery || constrain-width no-pad">
                {!mobileView && this.renderDesktopCarousel()}
                {mobileView && this.renderMobileCarousel()}
            </Section>
        )
    }
}
