import React from 'react'
import { Icon } from '../../partials/Icon/icon'
import { Section } from '../../partials/section'

export interface FileDownloadProps {
    fileName: string,
    extension: string,
    file: string, // string?
    title: string,
    showTitle: boolean
}

export function FileDownload(props: FileDownloadProps) {
    const { fileName, extension, file, title = '', showTitle} = props

    return (
        <Section title={showTitle ? title : null} type="constrain" className="file-download">
            <a className="file-download-inner" href={file}>
                <h4 className="title">{fileName}</h4>
                <div className="file-download-detail">
                    <span className="extension">{extension}</span>
                    <a className="link" href={file} target="_blank" title="download">
                        <Icon name="download" />
                    </a>
                </div>
            </a>
        </Section>
    )
}
