import React, { useEffect } from 'react'
import {DonationsForm} from './donationsForm';

export interface DonationsProps {
    title: string,
    showTitle: boolean,
    amounts: any[],
    stripeKey: string
}

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.


export function Donations(props: DonationsProps) {
    const { title, showTitle, amounts } = props

    return (
        <DonationsForm title={title} showTitle={showTitle} amounts={amounts}/>
    )
}
