import React from 'react'
import { Section } from '../../partials/section'

export interface WarningProps {
    description: string,
    buttonText: string
    url: string
}

export function Warning(props: WarningProps) {
    const { url, buttonText, description } = props

    return (
        <Section className="warning" type="constrain">
            <div className="warning-inner">
                <p className="warning-description">{description}</p>
                <a className="warning-button || button tertiary" href={url}>{buttonText}</a>
            </div>
        </Section>
    )
}
