import React from 'react'
import { Icon } from '../Icon/icon'

export interface DoctorProps {
    name: string,
    position: string,
    speciality: string,
    phone?: string,
    email?: string,
    website?: string,
    establishmentName: string,
    establishmentType: string,
    streetAddress: string,
    city: string,
}

export function Doctor(props: DoctorProps) {
    const {
        name,
        position,
        speciality,
        phone,
        email,
        website,
        establishmentName,
        establishmentType,
        streetAddress,
        city
    } = props

    return (
        <div className="doctor">
            <div className="doctor-details">
                <Icon name="user" />
                <span className="position">{position}</span>
                <h4 className="name">{name}</h4>
                <span className="speciality">{speciality}</span>
            </div>
            <address className="doctor-details">
                <Icon name="location" />
                <span className="clinic-label">{establishmentType}</span>
                <h4 className="clinic-name">{establishmentName}</h4>
                <div className="clinic-location">
                    <span className="street">{streetAddress}</span>
                    <span className="city">{city}</span>
                </div>
            </address>
            <div className="doctor-contact">
                {email && <a className="contact-link" href={`mailto:${email}`}>
                    <Icon className="contact-link-icon" name="email" />
                    <span className="contact-link-label">{email}</span>
                </a>}
                {phone && <a className="contact-link" href={`tel:${phone}`}>
                    <Icon className="contact-link-icon" name="phone" />
                    <span className="contact-link-label">{phone}</span>
                </a>}
                {website && <a className="contact-link" href={website} target="_blank" rel="noopener noreferrer">
                    <Icon className="contact-link-icon" name="website" />
                    <span className="contact-link-label">Website</span>
                </a>}
            </div>
        </div>
    )
}
