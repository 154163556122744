import React, { Component } from 'react'
import Slider from 'react-slick'
import { ARTICLES_CAROUSEL } from '../../../config/carousel'
import { isMobileView } from '../../../config/screen-width'
import { ArticleTile, ArticleTileProps } from '../../partials/ArticleTile/articleTile'
import { Section } from '../../partials/section'

export interface ArticleListProps {
    title: string
    showTitle: boolean
    items: Array<ArticleTileProps>
}

export interface ArticleListState {
    mobileView: boolean
}

export class ArticleList extends Component<ArticleListProps, ArticleListState> {
    carousel: any

    constructor(props: ArticleListProps) {
        super(props)

        this.state = {
            mobileView: false
        }

        this.carousel = React.createRef()
    }

    // Lifecycle
    componentDidMount() {
        this.setState({ mobileView: isMobileView() })
    }

    componentDidUpdate(nextProps: ArticleListProps) { // eslint-disable-line react/no-deprecated
        if (this.carousel && nextProps.items !== this.props.items && nextProps.title !== this.props.title) {
            this.carousel.current.slickGoTo(0)
        }
    }

    // Render
    renderMobileCarousel() {
        const { items } = this.props

        return (
            <div className="carousel">
                <Slider
                    className=""
                    ref={this.carousel}
                    {...ARTICLES_CAROUSEL}
                >
                    {items.map((article, index) => <ArticleTile {...article} key={index} />)}
                </Slider>
            </div>
        )
    }

    renderTiles() {
        const { items } = this.props

        return items.map((article, index) => <ArticleTile {...article} key={index} />)
    }

    render() {
        const { title, showTitle } = this.props
        const { mobileView } = this.state

        return (
            <Section title={showTitle ? title : null} type="constrain" className="article-tile-list">
                <div className="article-tile-list-inner">
                    {!mobileView && this.renderTiles()}
                    {mobileView && this.renderMobileCarousel()}
                </div>
            </Section>
        )
    }
}
