/**
 * Truncate string to a specified limit
 *
 * @param  {string} string String to be truncated
 * @param  {int} limit Length to truncate to
 *
 * @return {string} Truncated string with HTML encoded ellipsis
 */
export function truncate(string: string, limit: number): string {
    if (string.length > limit) {
        return `${string.substr(0, limit)} \u2026`
    }

    return string
}
