import React from 'react'
import { Section } from '../../partials/section'
import { Tile, TileProps } from '../../partials/tile/tile'

export interface TileListProps {
    title: string,
    showTitle: boolean,
    items: Array<TileProps>
}

export function TileList(props: TileListProps) {
    const { title, showTitle, items } = props

    return (
        <Section title={showTitle ? title : null} type="constrain" className="tile-list">
            <div className="tile-list-inner">
                {items.map((item, index) => {
                    return (
                        <Tile {...item} key={index} />
                    )
                })}
            </div>
        </Section>
    )
}
