import React, { Component } from 'react'

type VideoThumbnailProps = {
    url: string
}

type VideoThumbnailState = {
    isLoading: boolean
    title: string
    thumbnailUrl: string
}

const API = 'http://noembed.com/embed?url='

export class VideoThumbnail extends Component<VideoThumbnailProps, VideoThumbnailState> {
    constructor(props: VideoThumbnailProps) {
        super(props)

        this.state = {
            isLoading: false,
            title: null,
            thumbnailUrl: null
        }
    }

    componentDidMount() {
        fetch(`${API}${encodeURI(this.props.url)}`)
            .then((response) => {
                return response.json()
            })
            .then((data) => {
                this.setState({
                    isLoading: false,
                    title: data.title,
                    thumbnailUrl: data.thumbnail_url
                })
            })
            .catch((error) => {
                console.error((error))
            })
    }

    render() {
        const {isLoading, thumbnailUrl} = this.state

        if (isLoading) {
            return <p>Loading...</p>
        }

        return (
            <div className='video-thumbnail' style={{backgroundImage: `url(${thumbnailUrl})`}}/>
        )
    }
}
