import React, { Component } from 'react'
import { FilterBar, FilterOption } from '../FilterBar/filterBar'
import { NewsTile, NewsTileProps } from '../NewsTile/newsTile'
import { Section } from '../section'

export interface NewsFilterSectionProps {
    items: any
    options: Array<FilterOption>
}

export interface NewsFilterSectionState {
    selectedFilterOption: string | number,
    options: Array<FilterOption>
}

export class NewsFilterSection extends Component<NewsFilterSectionProps, NewsFilterSectionState> {
    constructor(props: NewsFilterSectionProps) {
        super(props)

        this.state = {
            selectedFilterOption: props.options[0].value,
            options: props.options
        }

        this.handleOnFilterClick = this.handleOnFilterClick.bind(this)
    }

    handleOnFilterClick(selectedFilterOption: string) {
        this.setState({ selectedFilterOption })
    }

    renderNewsList(article: NewsTileProps, index: number) {

        return (
            <li className="news-list-item" key={index}>
                <NewsTile {...article} />
            </li>
        )
    }

    render() {
        const { items, options } = this.props
        const { selectedFilterOption } = this.state

        return (
            <Section className="news">
                <FilterBar
                    selectedOption={selectedFilterOption}
                    options={options}
                    handleOnClick={this.handleOnFilterClick}
                />
                <ul className="news-list || constrain-width">
                    {
                        items.map((item: any, key: any) => {
                            if (selectedFilterOption && item.tags.find((o: any) => o.value === selectedFilterOption)) {
                                return this.renderNewsList(item, key)
                            }

                            if (!selectedFilterOption) {
                                return this.renderNewsList(item, key)
                            }

                            return null
                        })
                    }
                </ul>
            </Section>
        )
    }
}
