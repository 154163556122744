import React, { Component } from 'react'
import { Doctor, DoctorProps } from '../Doctor/doctor'
import { FilterBar, FilterOption } from '../FilterBar/filterBar'
import { Section } from '../section'

export interface FindDoctorSectionProps {
    title: string
    showTitle: boolean
    members: Array<Member>
    options: Array<FilterOption>
}

export interface FindDoctorSectionState {
    selectedFilterOption: string | number,
    options: Array<FilterOption>
}

export interface Member {
    city: string
    categories: Array<any>
    doctors: Array<Doctor>
}

export interface Doctor {
    name: string
}

export class FindDoctorSection extends Component<FindDoctorSectionProps, FindDoctorSectionState> {
    constructor(props: FindDoctorSectionProps) {
        super(props)

        this.state = {
            selectedFilterOption: props.options[0].value,
            options: props.options
        }

        this.handleOnFilterClick = this.handleOnFilterClick.bind(this)
    }

    handleOnFilterClick(selectedFilterOption: string) {
        this.setState({ selectedFilterOption })
    }

    renderDoctorList(listItem: any, index: number) {
        const { city, doctors } = listItem

        return (
            <li className="find-doctor-list-item" key={index}>
                <header className="find-doctor-list-item-header">
                    <div className="inner || constrain-width">
                        <h4 className="title">{city}</h4>
                    </div>
                </header>
                <ul className="doctor-list || constrain-width">
                    {doctors.map((doctor: DoctorProps, key: number) => {
                        return (
                            <li className="doctor-list-item" key={key}>
                                <Doctor {...doctor} />
                            </li>
                        )
                    })}
                </ul>
            </li>
        )
    }

    render() {
        const { title, showTitle,  members} = this.props
        const { selectedFilterOption, options } = this.state

        return (
            <Section title={showTitle ? title : null} className="find-doctor">
                <FilterBar
                    selectedOption={selectedFilterOption}
                    options={options}
                    handleOnClick={this.handleOnFilterClick}
                />
                <ul className="find-doctor-list">
                    {members.map((member, index) => {

                        if (member.categories.includes(selectedFilterOption)) {
                            return this.renderDoctorList(member, index)
                        }

                        return null
                    })}
                </ul>
            </Section>
        )
    }
}
