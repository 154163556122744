import 'config'
import * as React from 'react'
import ReactDOM from 'react-dom'
import { ArticleList } from './components/content-blocks/ArticleList/articleList'
import { Banner } from './components/content-blocks/Banner/banner'
import { ContentSet } from './components/content-blocks/ContentSet/contentSet'
import { Donations } from './components/content-blocks/Donations/donations'
import { Faq } from './components/content-blocks/Faq/faq'
import { FileDownload } from './components/content-blocks/FileDownload/fileDownload'
import { Hero } from './components/content-blocks/Hero/hero'
import { ImageGallery } from './components/content-blocks/ImageGallery/imageGallery'
import { TextContent } from './components/content-blocks/TextContent/textContent'
import { TileList } from './components/content-blocks/TileList/tileList'
import { Warning } from './components/content-blocks/Warning/warning'
import { FindDoctorSection } from './components/partials/FindDoctorSection/findDoctorSection'
import { Header, MenuItem } from './components/partials/Header/header'
import { NewsFilterSection } from './components/partials/NewsFilterSection/newsFilterSection'
import { OurTeamSection } from './components/partials/OurTeamSection/ourTeamSection'
import { SingleImage } from './components/content-blocks/SingleImage/singleImage'
import { CONFIG } from './config'
import './sass/index.scss'
import { VideoGallery } from './components/content-blocks/videoGallery/videoGallery'

const e = React.createElement

// Find all HTML hooks
document.querySelectorAll(CONFIG.header.main)
    .forEach(domContainer => {

        const data: MenuItem[] = JSON.parse((domContainer.getAttribute('data-navigation')))
        ReactDOM.render(
            e(Header, {data}),
            domContainer
        )
    })

document.querySelectorAll(CONFIG.heroBlock.main)
    .forEach(domContainer => {
        const data: any = JSON.parse((domContainer.getAttribute('data-block-data')))
        ReactDOM.render(
            e(Hero, ({title: data.title, showTitle: data.showTitle, imageUrl: data.image})),
            domContainer
        )
    })

document.querySelectorAll(CONFIG.contentBlock.main)
    .forEach(domContainer => {
        const data: any = JSON.parse((domContainer.getAttribute('data-block-data')))
        ReactDOM.render(
            e(TextContent, ({title: data.title, showTitle: data.showTitle, columns: data.columns, description: data.content})),
            domContainer
        )
    })

document.querySelectorAll(CONFIG.contentSetBlock.main)
    .forEach(domContainer => {
        const data: any = JSON.parse((domContainer.getAttribute('data-block-data')))
        ReactDOM.render(
            e(ContentSet, ({title: data.title, showTitle: data.showTitle, items: data.items})),
            domContainer
        )
    })

document.querySelectorAll(CONFIG.articleListBlock.main)
    .forEach(domContainer => {
        const data: any = JSON.parse((domContainer.getAttribute('data-block-data')))
        ReactDOM.render(
            e(ArticleList, ({title: data.title, showTitle: data.showTitle, items: data.items})),
            domContainer
        )
    })

document.querySelectorAll(CONFIG.bannerBlock.main)
    .forEach(domContainer => {
        const data: any = JSON.parse((domContainer.getAttribute('data-block-data')))
        ReactDOM.render(
            e(Banner, ({
                title: data.title,
                showTitle: data.showTitle,
                imageUrl: data.imageUrl,
                bannerUrl: data.bannerUrl,
                parentSlug: data.parentSlug,
                parentSlugTitle: data.parentSlugTitle
            })),
            domContainer
        )
    })

document.querySelectorAll(CONFIG.tileListBlock.main)
    .forEach(domContainer => {
        const data: any = JSON.parse((domContainer.getAttribute('data-block-data')))
        ReactDOM.render(
            e(TileList, ({title: data.title, showTitle: data.showTitle, items: data.items})),
            domContainer
        )
    })

document.querySelectorAll(CONFIG.imageGalleryBlock.main)
    .forEach(domContainer => {
        const data: any = JSON.parse((domContainer.getAttribute('data-block-data')))
        ReactDOM.render(
            e(ImageGallery, ({title: data.title, showTitle: data.showTitle, items: data.items})),
            domContainer
        )
    })

document.querySelectorAll(CONFIG.singleImageBlock.main)
    .forEach(domContainer => {
        const data: any = JSON.parse((domContainer.getAttribute('data-block-data')))
        ReactDOM.render(
            e(SingleImage, ({
                title: data.title,
                showTitle: data.showTitle,
                imageUrl: data.imageUrl,
            })),
            domContainer
        )
    })

document.querySelectorAll(CONFIG.donationsBlock.main)
    .forEach(domContainer => {
        const data: any = JSON.parse((domContainer.getAttribute('data-block-data')))
        ReactDOM.render(
            e(Donations, ({
                title: data.title,
                showTitle: data.showTitle,
                amounts: data.amounts,
                stripeKey: data.stripeKey
            })),
            domContainer
        )
    })

document.querySelectorAll(CONFIG.videoGalleryBlock.main)
    .forEach(domContainer => {
        const data: any = JSON.parse((domContainer.getAttribute('data-block-data')))
        ReactDOM.render(
            e(VideoGallery, ({title: data.title, showTitle: data.showTitle, items: data.items})),
            domContainer
        )
    })

document.querySelectorAll(CONFIG.accordionBlock.main)
    .forEach(domContainer => {
        const data: any = JSON.parse((domContainer.getAttribute('data-block-data')))
        ReactDOM.render(
            e(Faq, ({title: data.title, showTitle: data.showTitle, items: data.items})),
            domContainer
        )
    })

document.querySelectorAll(CONFIG.fileDownloadBlock.main)
    .forEach(domContainer => {
        const data: any = JSON.parse((domContainer.getAttribute('data-block-data')))
        ReactDOM.render(
            e(FileDownload, ({
                title: data.title,
                showTitle: data.showTitle,
                fileName: data.fileName,
                file: data.file,
                extension: data.extension
            })),
            domContainer
        )
    })

document.querySelectorAll(CONFIG.alertBlock.main)
    .forEach(domContainer => {
        const data: any = JSON.parse((domContainer.getAttribute('data-block-data')))
        ReactDOM.render(
            e(Warning, ({
                description : data.description,
                buttonText : data.buttonText,
                url : data.url
            })),
            domContainer
        )
    })

document.querySelectorAll(CONFIG.teamBlock.main)
    .forEach(domContainer => {
        const data: any = JSON.parse((domContainer.getAttribute('data-block-data')))
        ReactDOM.render(
            e(OurTeamSection, ({
                title: data.title,
                showTitle: data.showTitle,
                members : data.members,
                options : data.categoriesToFilter
            })),
            domContainer
        )
    })

document.querySelectorAll(CONFIG.blogBlock.main)
    .forEach(domContainer => {
        const data: any = JSON.parse((domContainer.getAttribute('data-block-data')))
        ReactDOM.render(
            e(NewsFilterSection, ({
                items : data.items,
                options : data.options
            })),
            domContainer
        )
    })

document.querySelectorAll(CONFIG.doctorsBlock.main)
    .forEach(domContainer => {
        const data: any = JSON.parse((domContainer.getAttribute('data-block-data')))
        ReactDOM.render(
            e(FindDoctorSection, ({
                title: data.title,
                showTitle: data.showTitle,
                members : data.members,
                options : data.categoriesToFilter
            })),
            domContainer
        )
    })
