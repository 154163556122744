export const CONFIG = {
    header: {
        main: '[data-hook="header"]'
    },
    heroBlock: {
        main: '[data-hook="hero-block"]'
    },
    contentBlock: {
        main: '[data-hook="content-block"]'
    },
    contentSetBlock: {
        main: '[data-hook="content-set-block"]'
    },
    articleListBlock: {
        main: '[data-hook="article-list-block"]'
    },
    bannerBlock: {
        main: '[data-hook="banner-block"]'
    },
    tileListBlock: {
        main: '[data-hook="tile-list-block"]'
    },
    donationsBlock: {
        main: '[data-hook="donations-block"]'
    },
    imageGalleryBlock: {
        main: '[data-hook="image-gallery-block"]'
    },
    singleImageBlock: {
        main: '[data-hook="single-image-block"]'
    },
    videoGalleryBlock: {
        main: '[data-hook="video-gallery-block"]'
    },
    accordionBlock: {
        main: '[data-hook="accordion-block"]'
    },
    fileDownloadBlock: {
        main: '[data-hook="file-download-block"]'
    },
    alertBlock: {
        main: '[data-hook="alert-block"]'
    },
    blogBlock: {
        main: '[data-hook="blog-block"]'
    },
    teamBlock: {
        main: '[data-hook="team-block"]'
    },
    doctorsBlock: {
        main: '[data-hook="doctors-block"]'
    }
}
