import classNames from 'classnames';
import React from 'react';
import stringToSlug from '../../utility/slugify';

interface sectionProps {
    children: JSX.Element | Array<JSX.Element> | string,
    description?: string,
    title?: string,
    type?: string,
    className? : string
};

export function Section({ children, description, title, type, className }: sectionProps) { // eslint-disable-line require-jsdoc
    return (
        <section
            className={`${classNames('section', { 'constrain-width': type === 'constrain' })} ${className}`}
            id={`section-${title ? stringToSlug(title, '-') : 'no-title'}`}>
            <header
                className={classNames('section-header', {
                    'constrain-width': type !== 'constrain',
                })}>
                { title &&
                    <h2 className="section-title">{title}</h2>
                }
                {description && <p>{description}</p>}
            </header>
            {children}
        </section>
    );
}
