import React from 'react'
import { truncate } from '../../../utility/truncate'

export interface ArticleTileProps {
    url: string,
    title: string,
    publishedDate: string,
    description: string
}

const MAXIMUM_CHARACTER_NUMBER = 120

export function ArticleTile(props: ArticleTileProps) {
    const { url, title, publishedDate, description } = props

    return (
        <a className="article-tile" href={url}>
            <div className="article-tile-inner">
                <header className="article-tile-header">
                    <h4 className="article-tile-title">{title}</h4>
                    <span className="article-tile-date">{publishedDate}</span>
                </header>
                <p >{truncate(description, MAXIMUM_CHARACTER_NUMBER)}</p>
                <a className="article-tile-link" href={url}>Read more</a>
            </div>
        </a>
    )
}
