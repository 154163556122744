import classnames from 'classnames'
import React, { Component } from 'react'
import { Icon } from '../../partials/Icon/icon'
import { Section } from '../../partials/section'

interface FaqProps {
    title: string
    showTitle: boolean
    items: Array<any>
}

interface FaqState {
    selectedIndex: number
}

export class Faq extends Component<FaqProps, FaqState> {
    constructor(props: FaqProps) {
        super(props)

        this.state = {
            selectedIndex: null
        }

        this.handleToggleClick = this.handleToggleClick.bind(this)
    }

    handleToggleClick(index: number) {
        const { selectedIndex } = this.state

        let nextIndex = null

        if (selectedIndex !== index) {
            nextIndex = index
        }

        this.setState({ selectedIndex: nextIndex })
    }

    render() {
        const { title, items } = this.props
        const { selectedIndex } = this.state

        return (
            <Section title={title} type="constrain">
                <ul className="accordion">
                    {items.map((item, index) => {
                        const itemClass = classnames('accordion-item', {
                            'is-active': index === selectedIndex
                        })

                        return (
                            <li className={itemClass} key={index}>
                                <button className="accordion-item-button" type="button" onClick={() => this.handleToggleClick(index)}>
                                    <h4 className="title">{item.title}</h4>
                                    <Icon className="icon" name="arrow-down" />
                                </button>
                                <div className="accordion-item-content">
                                    <div className="" dangerouslySetInnerHTML={{ __html: item.description }} />
                                </div>
                            </li>
                        )
                    })}
                </ul>
            </Section>
        )
   }
}
