import * as React from 'react'

export interface IconProps {
    name: string
    title?: string,
    className?: string
}

export const Icon: React.SFC<IconProps & any> = React.memo(({
    title,
    name,
    className,
    ...options
}) => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" {...options} className={`${className ? className : 'icon'}`}>
            <title>{title}</title>
            <use xlinkHref={`/resources/themes/allergy/images/icons/_icon.svg#${name}`}/>
        </svg>
    )
})
