import React, { useEffect } from 'react'
import { Section } from '../../partials/section'

export interface DonationsFormProps {
    title: string,
    showTitle: boolean,
    amounts: any[],
}

export function DonationsForm(props: DonationsFormProps) {
    const {title, showTitle, amounts} = props

    function initPayPalButton() {
        const description: any = document.querySelector('#smart-button-container #description')
        const amount: any = document.querySelector('#smart-button-container #amount')
        const descriptionError: any = document.querySelector('#smart-button-container #descriptionError')
        const priceError: any = document.querySelector('#smart-button-container #priceLabelError')
        const invoiceid: any = document.querySelector('#smart-button-container #invoiceid')
        const invoiceidError: any = document.querySelector('#smart-button-container #invoiceidError')
        const invoiceidDiv: any = document.querySelector('#smart-button-container #invoiceidDiv')
        const elArr = [description, amount]
        if (invoiceidDiv.firstChild.innerHTML.length > 1) {
            invoiceidDiv.style.display = 'block'
        }
        const purchaseUnits: any = []
        purchaseUnits[0] = {}
        purchaseUnits[0].amount = {}

        function validate(event: any) {
            return event.value.length > 0
        }

        (window as any).paypal.Buttons({
            style: {
                color: 'gold',
                shape: 'rect',
                label: 'paypal',
                layout: 'vertical',
            },
            onInit: function (data: any, actions: any) {
                actions.disable()
                if (invoiceidDiv.style.display === 'block') {
                    elArr.push(invoiceid)
                }
                elArr.forEach(function (item: any) {
                    item.addEventListener('keyup', function (event: any) {
                        const result = elArr.every(validate)
                        if (result) {
                            actions.enable()
                        } else {
                            actions.disable()
                        }
                    })
                })
            },
            onClick: function () {
                if (description.value.length < 1) {
                    descriptionError.style.visibility = 'visible'
                } else {
                    descriptionError.style.visibility = 'hidden'
                }
                if (amount.value.length < 1) {
                    priceError.style.visibility = 'visible'
                } else {
                    priceError.style.visibility = 'hidden'
                }
                if (invoiceid.value.length < 1 && invoiceidDiv.style.display === 'block') {
                    invoiceidError.style.visibility = 'visible'
                } else {
                    invoiceidError.style.visibility = 'hidden'
                }
                purchaseUnits[0].description = description.value
                purchaseUnits[0].amount.value = amount.value
                if (invoiceid.value !== '') {
                    purchaseUnits[0].invoice_id = invoiceid.value
                }
            },
            createOrder: function (data: any, actions: any) {
                return actions.order.create({
                    purchase_units: purchaseUnits,
                })
            },
            onApprove: function (data: any, actions: any) {
                return actions.order.capture().then(function (orderData: any) {
                    // Full available details
                    console.log('Capture result', orderData, JSON.stringify(orderData, null, 2))
                    // Show a success message within this page, e.g.
                    const element = document.getElementById('paypal-button-container')
                    element.innerHTML = ''
                    element.innerHTML = '<h3>Thank you for your payment!</h3>'
                    // Or go to another URL:  actions.redirect('thank_you.html');
                })
            },
            onError: function (err: any) {
                console.log(err)
            }
        }).render('#paypal-button-container')
    }

    useEffect(() => {
        initPayPalButton()
    }, [])

    return (
        <Section title={showTitle ? title : null} type="constrain" className="single-image-block">
            <div id="smart-button-container">
                <div className="paypal-form">
                    <label htmlFor="description" className="paypal-form-label">Description</label>
                    <input type="text" name="descriptionInput" id="description" />
                </div>
                <p id="descriptionError" style={{visibility: 'hidden', color: 'red', textAlign: 'center'}}>Please enter a description</p>
                <div className="paypal-form">
                    <label htmlFor="amount" className="paypal-form-label">Payment Amount</label>
                    <input name="amountInput" type="number" id="amount"/>
                    <span className="paypal-form-span">NZD</span>
                </div>
                <p id="priceLabelError" style={{visibility: 'hidden', color: 'red', textAlign: 'center'}}>Please enter a price</p>
                <div id="invoiceidDiv" style={{textAlign: 'center', display: 'none'}}>
                    <label htmlFor="invoiceid"> </label>
                    <input name="invoiceid" maxLength={127} type="text" id="invoiceid" />
                </div>
                <p id="invoiceidError" style={{visibility: 'hidden', color: 'red', textAlign: 'center'}}>Please enter an Invoice ID</p>
                <div style={{textAlign: 'center', marginTop: '0.625rem'}} id="paypal-button-container"/>
            </div>
        </Section>
    )
}
