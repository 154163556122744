import React from 'react'

export interface ContentSetTileProps {
    title: string,
    image: string,
    url: string
}

export function ContentSetTile(props: ContentSetTileProps) {
    const { title, image, url } = props

    return (
        <article className="content-set-tile">
            <a href={url} className="content-set-tile-inner">
                <div className="content-set-tile-media || preserve-aspect-ratio" style={{ backgroundImage: `url(${image})` }}>
                    <div className="preserve-aspect-ratio-media">
                        <img className="fixed-image" src={image} alt={title} />
                    </div>
                </div>
                <div className="content-set-tile-content">
                    <h3 className="title">{title}</h3>
                </div>
            </a>
        </article>
    )
}
