import classnames from 'classnames'
import React, { Component } from 'react'
import { Icon } from '../Icon/icon'

export interface FilterOption {
    label: string,
    value: string | number
}

export interface FilterBarProps {
    options: Array<FilterOption>,
    selectedOption: string | number,
    handleOnClick: Function
}

export interface FilterBarState {
    toggleIsOpen: boolean
}

export class FilterBar extends Component<FilterBarProps, FilterBarState> {
    constructor(props: FilterBarProps) {
        super(props)

        this.state = {
            toggleIsOpen: false
        }

        this.handleToggleClick = this.handleToggleClick.bind(this)
    }

    handleToggleClick() {
        this.setState({ toggleIsOpen: !this.state.toggleIsOpen })
    }

    render() {
        const { options, selectedOption, handleOnClick } = this.props
        const { toggleIsOpen } = this.state

        const filterLabel = options.find((option) => option.value === selectedOption).label

        const toggleClass = classnames('filter-bar-toggle', {
            'is-active': toggleIsOpen
        })

        const listClass = classnames('filter-bar-list', {
            'is-hidden': !toggleIsOpen
        })

        return (
            <div className="filter-bar">
                <div className="filter-bar-inner || constrain-width no-pad">
                    <div className={toggleClass}>
                        <button className="filter-bar-toggle-button" onClick={this.handleToggleClick}>
                            <span className="filter-bar-toggle-title">Filter</span>
                            <span className="filter-bar-toggle-label">({filterLabel})</span>
                            <Icon name="arrow-down" />
                        </button>
                    </div>
                    <div className={listClass}>
                        {options.map(({ value, label }: FilterOption, index) => {
                            const optionClass = classnames('filter-bar-option', {
                                'is-active': selectedOption === value
                            })

                            return (
                                <button className={optionClass} type="button" onClick={() => handleOnClick(value)} key={index}>
                                    {label}
                                </button>
                            )
                        })}
                    </div>
                </div>
            </div>
        )
    }
}
