import classnames from 'classnames'
import React, { Component } from 'react'
import Slider from 'react-slick'
import { IMAGE_GALLERY_CAROUSEL } from '../../../config/carousel'
import { isMobileView } from '../../../config/screen-width'
import { Icon } from '../../partials/Icon/icon'
import { Section } from '../../partials/section'

const MIN_SLIDES_NUMBER = 6

export interface ImageGalleryItemProps {
    title: string
    image: string
}

export interface ImageGalleryProps {
    title: string
    showTitle: boolean
    items: Array<ImageGalleryItemProps>
}

export interface ImageGalleryState {
    currentIndex: number
    mobileView: boolean
}

export class ImageGallery extends Component<ImageGalleryProps, ImageGalleryState> {
    carousel: any

    constructor(props: ImageGalleryProps) {
        super(props)

        this.carousel = React.createRef()
        this.handleOnPreviousClick = this.handleOnPreviousClick.bind(this)
        this.handleOnNextClick = this.handleOnNextClick.bind(this)

        this.state = {
            currentIndex: 0,
            mobileView: true
        }
    }

    // Lifecycle
    componentDidMount() {
        this.setState({ mobileView: isMobileView() })
    }

    componentDidUpdate(nextProps: ImageGalleryProps) { // eslint-disable-line react/no-deprecated
        if (this.carousel && nextProps.items !== this.props.items && nextProps.title !== this.props.title) {
            this.carousel.current.slickGoTo(0)
        }
    }

    // componentWillReceiveProps(nextProps: ImageGalleryProps) { // eslint-disable-line react/no-deprecated
    //     if (this.carousel && nextProps.items !== this.props.items && nextProps.title !== this.props.title) {
    //         this.carousel.current.slickGoTo(0)
    //     }
    // }

    // Handler
    handleOnNextClick() {
        this.carousel.current.slickGoTo(this.state.currentIndex + 1)
    }

    handleOnPreviousClick() {
        this.carousel.current.slickPrev(this.state.currentIndex - 1)
    }

    // Render
    renderNumberOfSlides() {
        const { items } = this.props
        const { currentIndex } = this.state

        return (
            <span className="carousel-range" aria-hidden={true}>{`${currentIndex + 1} of ${items.length}`}</span>
        )
    }

    renderDesktopCarousel() {
        const { items } = this.props
        const { currentIndex } = this.state

        return (
            <div className="image-gallery-inner">
                {items.map((item, index) => {
                    const imageClass = classnames('image-gallery-content', {
                        'is-hidden': index !== currentIndex
                    })

                    return (
                        <div className={imageClass} key={index}>
                            <div className="image-gallery-image || preserve-aspect-ratio" style={{ backgroundImage: `url(${item.image})` }}>
                                <div className="preserve-aspect-ratio-media">
                                    <img className="fixed-image" src={item.image} alt={''}/>
                                </div>
                            </div>
                            <p className="image-gallery-description">{item.title}</p>
                        </div>
                    )
                })}
                <div className="carousel">
                    {this.renderNumberOfSlides()}
                    {items.length >= MIN_SLIDES_NUMBER &&
                        <div>
                            <button className="carousel-button previous" onClick={this.handleOnPreviousClick}>
                                <Icon name="arrow-left" />
                            </button>
                            <button className="carousel-button next" onClick={this.handleOnNextClick}>
                                <Icon name="arrow-right" />
                            </button>
                        </div>
                    }
                    <Slider
                        className="carousel-slider"
                        ref={this.carousel}
                        {...IMAGE_GALLERY_CAROUSEL}
                        beforeChange={(previous, current) => {
                            this.setState({ currentIndex: current })
                        }}
                    >
                        {items.map((item, index) => {
                            return (
                                <div className="image-gallery-item" key={index}>
                                    <div className="image-gallery-item-media || preserve-aspect-ratio overlay-image" style={{ backgroundImage: `url(${item.image})` }}>
                                        <div className="preserve-aspect-ratio-media">
                                            <img className="fixed-image" src={item.image} alt={item.title} />
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </Slider>
                </div>
            </div>
        )
    }

    renderMobileCarousel() {
        const { items } = this.props

        return (
            <div className="image-gallery-inner">
                <div className="carousel">
                    <Slider
                        className="carousel-slider"
                        ref={this.carousel}
                        {...IMAGE_GALLERY_CAROUSEL}
                        beforeChange={(previous, current) => {
                            this.setState({ currentIndex: current })
                        }}
                    >
                        {items.map((item, index) => {
                            return (
                                <div className="image-gallery-item" key={index}>
                                    <div className="image-gallery-item-media || preserve-aspect-ratio overlay-image" style={{ backgroundImage: `url(${item.image})` }}>
                                        <div className="preserve-aspect-ratio-media">
                                            <img className="fixed-image" src={item.image} alt={item.title} />
                                        </div>
                                    </div>
                                    <div className="image-gallery-item-content">
                                        <p className="description">{item.title}</p>
                                    </div>
                                </div>
                            )
                        })}
                    </Slider>
                </div>
            </div>
        )
    }

    render() {
        const { title, items } = this.props
        const { mobileView } = this.state

        if (items.length < MIN_SLIDES_NUMBER) {
            return (
                <div>{`Minimum amount of slides for Image Gallery is ${MIN_SLIDES_NUMBER}`}</div>
            )
        }

        return (
            <Section title={title} className="image-gallery || constrain-width no-pad">
                {!mobileView && this.renderDesktopCarousel()}
                {mobileView && this.renderMobileCarousel()}
            </Section>
        )
    }
}
