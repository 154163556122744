/**
 * Add or remove the class to the body
 *
 * @param {bool} fix
 */
export function updateBody(fix: boolean = true, className: string = 'is-fixed') {
    const body = document.body

    // Add or remove the 'is-fixed' class to the body, which makes the body fixed positioning, avoiding the user to scroll (useful for when mobile navigation is open)
    if (fix) {
        body.classList.add(className)
    } else {
        body.classList.remove(className)
    }
}
