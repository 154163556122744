import React from 'react'
import { Icon } from '../Icon/icon'

export interface TeamMemberProps {
    name: string,
    position: string,
    phone: string,
    email: string,
    categories: any
}

export function TeamMember(props: TeamMemberProps) {
    const { name, position, phone, email } = props

    return (
        <div className="team-member">
            <div className="team-member-details">
                <Icon name="user" />
                <h4 className="name">{name}</h4>
                <span className="position">{position}</span>
            </div>
            <div className="team-member-contact">
                <a className="contact-link" href={`mailto:${email}`}>
                    <Icon className="contact-link-icon" name="email" />
                    <span className="contact-link-label">Email</span>
                </a>
                <a className="contact-link" href={`tel:${phone}`}>
                    <Icon className="contact-link-icon" name="phone" />
                    <span className="contact-link-label">Phone</span>
                </a>
            </div>
        </div>
    )
}
