import React, { Component } from 'react'
import { FilterBar, FilterOption } from '../FilterBar/filterBar'
import { Section } from '../section'
import { TeamMember, TeamMemberProps } from '../TeamMember/teamMember'

export interface OurTeamSectionProps {
    title: string
    showTitle: boolean
    members: Array<TeamMemberProps>
    options: Array<FilterOption>
}

export interface OurTeamSectionState {
    selectedFilterOption: string | number,
    options: Array<FilterOption>
}

export class OurTeamSection extends Component<OurTeamSectionProps, OurTeamSectionState> {
    constructor(props: OurTeamSectionProps) {
        super(props)

        this.state = {
            selectedFilterOption: props.options[0].value,
            options: props.options
        }

        this.handleOnFilterClick = this.handleOnFilterClick.bind(this)
    }

    handleOnFilterClick(selectedFilterOption: string) {
        this.setState({ selectedFilterOption })
    }

    render() {
        const { members, title, showTitle } = this.props
        const { selectedFilterOption, options } = this.state

        return (
            <Section title={showTitle ? title : null} className="our-team">
                <FilterBar
                    selectedOption={selectedFilterOption}
                    options={options}
                    handleOnClick={this.handleOnFilterClick}
                />
                <div className="team-member-list || constrain-width">
                    <div className="team-member-list-inner">
                        {
                            // members.map((member, index) => <TeamMember {...member} key={index} />)
                            members.map((member, index) => {
                                // return <TeamMember {...member} key={index} />
                                if (selectedFilterOption && member.categories.find((o: any) => o.value === selectedFilterOption)) {
                                    return <TeamMember {...member} key={index} />
                                }

                                if (!selectedFilterOption) {
                                    return <TeamMember {...member} key={index} />
                                }

                                return null
                            })
                        }
                    </div>
                </div>
            </Section>
        )
    }
}
