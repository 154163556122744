import React from 'react'
import { truncate } from '../../../utility/truncate'

export interface NewsTileProps {
    url: string,
    title: string,
    publishedDate: string,
    description: string,
    tags?: Array<Tag>,
    imageUrl: string
}

export interface Tag {
    label: string
    value: number
}

const MAXIMUM_CHARACTER_DESCRIPTION = 200

export function NewsTile(props: NewsTileProps) {
    const { url, title, publishedDate, description, tags, imageUrl } = props

    return (
        <a className="news-tile" href={url}>
            <div className="news-tile-inner">
                <div className="news-tile-media || preserve-aspect-ratio" style={{ backgroundImage: `url(${imageUrl})` }}>
                    <div className="preserve-aspect-ratio-media">
                        <img className="fixed-image" src={imageUrl} alt={title} />
                    </div>
                </div>
                <div className="news-tile-content">
                    <header className="news-tile-header">
                        <h3 className="news-tile-title">{title}</h3>
                        <span className="news-tile-date">{publishedDate}</span>
                        {tags &&
                        <span className="news-tile-tags">
                            {
                                tags.map((tag: Tag) => {
                                    return `${tag.label} `
                                })
                            }
                        </span>}
                        {/*{tags && <span className="news-tile-tags">{truncate(tags, MAXIMUM_CHARACTER_TAG)}</span>}*/}
                    </header>
                    <p className="news-tile-description">{truncate(description, MAXIMUM_CHARACTER_DESCRIPTION)}</p>
                    <a className="news-tile-link" href={url}>Read more</a>
                </div>
            </div>
        </a>
    )
}
