import React, { Component } from 'react'
import Slider from 'react-slick'
import { CONTENT_SET_CAROUSEL } from '../../../config/carousel'
import { ContentSetTile, ContentSetTileProps } from '../../partials/ContentSetTile/contentSetTile'
import { Icon } from '../../partials/Icon/icon'
import { Section } from '../../partials/section'

const MIN_SLIDES_NUMBER = 3

export interface ContentSetProps {
    title: string
    showTitle: boolean
    items: Array<ContentSetTileProps>
}

export class ContentSet extends Component<ContentSetProps> {
    carousel: any

    constructor(props: ContentSetProps) {
        super(props)

        this.carousel = React.createRef()
        this.handleOnPreviousClick = this.handleOnPreviousClick.bind(this)
        this.handleOnNextClick = this.handleOnNextClick.bind(this)
    }

    // Lifecycle
    componentWillReceiveProps(nextProps: ContentSetProps) { // eslint-disable-line react/no-deprecated
        if (this.carousel && nextProps.items !== this.props.items && nextProps.title !== this.props.title) {
            this.carousel.current.slickGoTo(0)
        }
    }

    // Handler
    handleOnNextClick() {
        this.carousel.current.slickNext()
    }

    handleOnPreviousClick() {
        this.carousel.current.slickPrev()
    }

    // Render
    render() {
        const { title, items, showTitle } = this.props

        return (
            <Section title={showTitle ? title : null} type="constrain" className="content-set">
                <div className="content-set-inner">
                    <div className="carousel">
                        {items.length > MIN_SLIDES_NUMBER &&
                            <div>
                                <button className="content-set-button previous || carousel-button" onClick={this.handleOnPreviousClick}>
                                    <Icon name="arrow-left" />
                                </button>
                                <button className="content-set-button next || carousel-button" onClick={this.handleOnNextClick}>
                                    <Icon name="arrow-right" />
                                </button>
                            </div>
                        }
                        <Slider
                            className="carousel-slider"
                            ref={this.carousel}
                            {...CONTENT_SET_CAROUSEL}
                        >
                            {items.map((item, index) => <ContentSetTile {...item} key={index} />)}
                        </Slider>
                    </div>
                </div>
            </Section>
        )
    }
}
