import { TABLET_SCREEM_WIDTH } from './screen-width'

/*
    This file should be used to store carousel configuration constants
*/
export const ARTICLES_CAROUSEL = {
    dots: true,
    draggable: true,
    infinite: true,
    speed: 200,
    swipe: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: false,
    adaptiveHeight: true
}

export const CONTENT_SET_CAROUSEL = {
    dots: true,
    draggable: false,
    infinite: true,
    speed: 200,
    swipe: true,
    arrows: false,
    slidesToShow: 3,
    slidesToScroll: 3,
    centerMode: false,
    adaptiveHeight: true,
    responsive: [
        {
            breakpoint: TABLET_SCREEM_WIDTH,
            settings: {
                autoplay: false,
                draggable: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                centerMode: false,
                adaptiveHeight: true
            }
        }
    ]
}

export const IMAGE_GALLERY_CAROUSEL = {
    dots: false,
    draggable: false,
    infinite: true,
    speed: 300,
    swipe: false,
    arrows: false,
    slidesToShow: 5,
    slidesToScroll: 1,
    centerMode: true,
    adaptiveHeight: true,
    vertical: true,
    verticalSwiping: true,
    responsive: [
        {
            breakpoint: TABLET_SCREEM_WIDTH,
            settings: {
                draggable: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                centerMode: false,
                vertical: false,
                verticalSwiping: false,
                swipe: true,
                dots: true
            }
        }
    ]
}

export const VIDEO_GALLERY_CAROUSEL = {
    dots: false,
    draggable: false,
    infinite: true,
    speed: 300,
    swipe: false,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    adaptiveHeight: true,
    vertical: true,
    verticalSwiping: true,
    responsive: [
        {
            breakpoint: TABLET_SCREEM_WIDTH,
            settings: {
                draggable: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                centerMode: false,
                vertical: false,
                verticalSwiping: false,
                swipe: true,
                dots: true
            }
        }
    ]
}
