import React from 'react'
import { Icon } from '../../partials/Icon/icon'

export interface BannerProps {
    title: string,
    showTitle: boolean,
    imageUrl: string,
    bannerUrl?: string,
    parentSlug?: string,
    parentSlugTitle?: string
}

export function Banner(props: BannerProps) {
    const { title, showTitle, imageUrl, bannerUrl, parentSlug, parentSlugTitle } = props
    
    return (
        <header role="heading" className="banner">
            <div className="banner-wrapper" style={{backgroundImage: `url(${bannerUrl ? bannerUrl : imageUrl})`}}>
                <div className="banner-inner">
                    <div className="banner-header || constrain-width">
                        { showTitle ? <h1 className="banner-title || heading-2">{title}</h1> : null }
                        { parentSlug && parentSlugTitle &&
                            <a className="banner-link" href={parentSlug}>
                                <Icon name="back-arrow" />
                                <span className="banner-link-description">{parentSlugTitle}</span>
                            </a>
                        }
                    </div>
                </div>
            </div>
        </header>
    )
}
