import React from 'react'
import {Section} from '../../partials/section'

export interface SingleImageProps {
    title: string,
    showTitle: boolean,
    imageUrl: string,
}

export function SingleImage(props: SingleImageProps) {
    const { title, showTitle, imageUrl } = props

    return (
        <Section title={showTitle ? title : null} type="constrain" className="single-image-block">
            <img className="single-image-block_image" src={imageUrl} alt={title} />
        </Section>
    )
}
